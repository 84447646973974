import '../styles/Home.css';

function Home () {
    return (
        <div className='pra-home'>
            <h1>
                développeur full stack Junior<br/>
                <div className="pra-name">Rozke Adine BOUYAHIA</div>
            </h1>
        </div>

    )
}

export default Home