// import donkeycar from "../assets/donkeycar.png";

function NotFound () {
    return (
    <div>
        <h1 className="message__title">Page Not Found</h1>
    </div>
    )
}

export default NotFound